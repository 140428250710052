import React from 'react';
import MapNavbar from './MapNavbar.jsx'; //ERROR when imported
import '../css/app.css'

const App = (props) => {
  return (
    <div>
        <MapNavbar />
    </div>
  );
};

export default App;